body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #2a252a;
}

h1, h2, h3, h4, h5, h6, p {
  margin: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;

  scrollbar-color: #ffffff transparent;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
    width: 4px;
}

*::-webkit-scrollbar-thumb {
    background: #ffffff;
}

*::-webkit-scrollbar-track-piece {
    background: transparent;
}